const offices = [
  {
    city: "Kielce",
    investments: [
      {
        url: "/biura-i-lokale/kielce/sky-trust/",
        city: "Kielce",
        logo: require("assets/images/logo-sky-trust.svg").default,
        image: require("assets/images/investments/st-office-tile-image.jpg")
          .default,
        title: "Sky Trust biura i lokale",
        address_preffix: "al.",
        address: "Solidarności",
        office_areas: "od 45",
        offices: "24",
        status: "W sprzedaży",
        deadline: "2025",
        investment: "sky_trust_offices",
      },
    ],
    office: {
      address_preffix: "Al.",
      address: "Solidarności 34 (parking Exbud)",
      city: "25-323 Kielce",
      hours: "pn-pt: 10:00 - 18:00",
      map: {
        lat: 50.881796,
        lng: 20.6534564,
      },
    },
  },
]

export default offices
