import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Content } from "page_components/investments"

import offices from "data/offices"

const Apartments = ({ location }) => {
  const title = "Biura i lokale"

  return (
    <Layout
      location={location}
      seo={{
        title: "Nowe biura na sprzedaż",
        description:
          "Mieszkania na sprzedaż bezpośrednio od sprawdzonego dewelopera. Najlepsze lokalizacje, przemyślane projekty i zawsze dużo zieleni.",
      }}
      rmGlobalPopup
    >
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      {offices?.map((item, index) => (
        <Content
          key={index}
          city={item?.city}
          sub_title="Inwestycje w sprzedaży"
          investments={item?.investments}
        />
      ))}
    </Layout>
  )
}

export default Apartments
